<template>
  <b-modal
    id="email-modal"
    centered
    title="Müşteri E-Posta Gönder"
    size="lg"
    ok-only
    hide-footer
  >
    <b-card-text v-if="!email.send">
      <b-form-group
        label="Kime"
        label-for="to"
      >
        <b-form-tags
          id="to"
          v-model="email.to"
          placeholder="Kime"
          add-button-text="Ekle"
        />
      </b-form-group>
      <b-form-group
        label="Bilgi"
        label-for="cc"
      >
        <b-form-tags
          id="cc"
          v-model="email.cc"
          placeholder="Bilgi"
          add-button-text="Ekle"
        />
      </b-form-group>
      <b-form-group
        label="E-Posta İçeriği"
        label-for="content"
      >
        <quill-editor
          id="content"
          v-model="email.content"
          class="bg-white text-black-50"
          :options="snowOption"
        >
          <div
            id="toolbar"
            slot="toolbar"
          >
            <button class="ql-bold">
              Bold
            </button>
            <button class="ql-italic">
              Italic
            </button>
          </div>
        </quill-editor>
      </b-form-group>
      <b-alert show>
        <div class="alert-body">
          <FeatherIcon icon="InfoIcon" />
          Servis formu dosya eki olarak gönderilecektir.
        </div>
      </b-alert>
      <b-button
        variant="danger"
        @click="submitMail"
      >
        <FeatherIcon icon="SendIcon" /> Gönder
      </b-button>
    </b-card-text>
    <b-card-text v-else>
      <b-alert
        show
        variant="success"
      >
        <div
          v-if="saveStatus.status === null"
          class="alert-body text-center"
        >
          <b-spinner />
          <p>E-Posta gönderiliyor. Lütfen bekleyiniz...</p>
        </div>
        <div
          v-else
          class="alert-body text-center"
        >
          <FeatherIcon icon="CheckIcon" />
          <p>{{ saveStatus.message }}</p>
        </div>
      </b-alert>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText, BModal, BFormGroup, BFormTags, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'EmailModal',
  components: {
    BCardText,
    BModal,
    BFormGroup,
    BFormTags,
    quillEditor,
    BButton,
    BAlert,
    BSpinner,
  },
  data() {
    return {
      email: {
        id: this.$route.params.id,
        to: [],
        cc: ['destek@dramaendustri.com'],
        content: '<p>Drama Endüstri analiz formunu ekte bulabilirsiniz.</p><p><em>Bu mesaj sistem tarafından otomatik gönderilmiştir.</em></p>',
        send: false,
      },
      snowOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    customer: {
      deep: true,
      handler(val) {
        if (val.email) {
          this.email.to.push(val.email)
        }
      },
    },
  },
  methods: {
    submitMail() {
      this.email.send = true
      this.$store.dispatch('services/serviceMail', this.email)
        .then(res => {
          if (res) {
            this.$store.dispatch('services/serviceView', this.$route.params.id)
            this.email.send = false
          }
        })
    },
  },
}
</script>
